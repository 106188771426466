beamer_config = {
  product_id: 'oeoPruyu26965', //DO NOT CHANGE: This is your product code on Beamer
  selector: '.beamer-button,.beamerTrigger,a[href=\"#beamerTrigger\"]', /*Optional: Id, class (or list of both) of the HTML element to use as a button*/
  bounce: false,
  // display : 'right', /*Optional: Choose how to display the Beamer panel in your site*/
  //top: 0, /*Optional: Top position offset for the notification bubble*/
  //right: 0, /*Optional: Right position offset for the notification bubble*/
  //bottom: 0, /*Optional: Bottom position offset for the notification bubble*/
  //left: 0, /*Optional: Left position offset for the notification bubble*/
  //button_position: 'bottom-right', /*Optional: Position for the notification button that shows up when the selector parameter is not set*/
  icon: 'bell_lines', /*Optional: Alternative icon to display in the notification button*/
  // language: 'en', /*Optional: Bring news in the language of choice*/
  //filter: 'cc', /*Optional : Bring the news for a certain role as well as all the public news*/
  //lazy: true, /*Optional : true if you want to manually start the script by calling Beamer.init()*/
  //alert : true, /*Optional : false if you don't want to initialize the selector*/
  //delay : 0, /*Optional : Delay (in milliseconds) before initializing Beamer*/
  //embed : false, /*Optional : true if you want to embed and display the feed inside the element selected by the 'selector' parameter*/
  //mobile : true, /*Optional : false if you don't want to initialize Beamer on mobile devices*/
  //notification_prompt : 'sidebar', /*Optional : override the method selected to prompt users for permission to receive web push notifications*/
  //callback : your_callback_function, /*Optional : Beamer will call this function, with the number of new features as a parameter, after the initialization*/
  //onclick : your_onclick_function(url, openInNewWindow), /*Optional : Beamer will call this function when a user clicks on a link in one of your posts*/
  //onopen : your_onopen_function, /*Optional : Beamer will call this function when opening the panel*/
  //onclose : your_onclose_function, /*Optional : Beamer will call this function when closing the panel*/
  //---------------Visitor Information---------------
  //user_firstname : "firstname", /*Optional : Input your user firstname for better statistics*/
  //user_lastname : "lastname", /*Optional : Input your user lastname for better statistics*/
  //user_email : "email", /*Optional : Input your user email for better statistics*/
  //user_id : "user_id" /*Optional : Input your user ID for better statistics*/
};
